.limitationView {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-mono-1);
    padding: var(--space-48);
}

.message {
    margin-top: var(--space-24);
    text-align: center;
}
