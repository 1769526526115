.desktopViewCont {
    position: absolute;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    align-items: stretch;

    background-color: var(--color-mono-1);
}

.centeredView {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-mono-1);
}

@media (min-width: 1600px) and (min-height: 960px) {
    .desktopViewCont {
        left: 50%;
        top: 50%;
        margin-left: -800px;
        margin-top: -480px;
        max-width: 1600px;
        max-height: 960px;

        border-radius: 16px;
        box-shadow: var(--shadow-3);
        overflow: hidden;
        /* fix border-radius when content overflows and a scrollbar is added: */
        /* -webkit-mask-image: -webkit-radial-gradient(white, black); */
    }
}

.contentCont {
    position: relative;
    flex: 2;
    overflow: hidden;
}

.pageModuleCont {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    min-width: 300px;

    padding-top: calc(48px + 10vh); /* Should be aligned with DesktopSidePanel.module.css .container margin-top*/
    padding-inline-start: var(--space-64);
    padding-inline-end: 20vw;
    padding-bottom: 48px;
    overflow-y: auto;
}

.bottomFade {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 64px;

    background: linear-gradient(0deg, var(--color-mono-1) 0%, rgba(255, 255, 255, 0) 100%);
}

@media (prefers-color-scheme: dark) {
    .bottomFade {
        background: linear-gradient(0deg, var(--color-mono-1) 0%, transparent 100%);
    }
}
