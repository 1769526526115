/* MOBILE */
.itemsListCont_mobile {
    padding: var( --space-12 );
    padding-bottom: var( --space-96 );
    flex: 1;
    min-height: fit-content;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.itemCont {
    display: flex;
    margin-bottom: var( --space-12 );
    width: 100%;
}


/* DESKTOP */

.itemsListCont_desktop {
    flex: 1;
    min-height: fit-content;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.desktopOpenInputCont {
    position: relative;
    min-height: 160px;
}