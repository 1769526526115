[data-platform='mobile'] .itemsListCont {
    position: sticky;
    bottom: 0;
    flex: 1;

    overflow: hidden;
}

[data-platform='desktop'] .itemsListCont {
    /* position: relative; */
    position: sticky;
    flex: 1 1;
    height: 100vh;

    /* Should be aligned with DesktopSidePanel.module.css .container margin-top*/
    margin-top: calc(-48px - 10vh);
    margin-bottom: -118px;

    overflow: hidden;
}

[data-platform='mobile'] .scaleItemCont {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* height: 100%; */
    /* margin: var( --space-32 ) 0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--space-24);
    padding-bottom: var(--space-128);
}

[data-platform='desktop'] .scaleItemCont {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    padding-top: calc(48px + 10vh); /* Should be aligned with DesktopSidePanel.module.css .container margin-top*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.valueButtonsCont {
    display: flex;
    justify-content: space-evenly;
}

[data-platform='desktop'] .valueButtonsCont {
    justify-content: space-between;
}

.valueButtonWrapper {
    display: flex;
    justify-content: center;
    flex: 1;
    margin: 2px;
    min-width: 32px;
}

[data-platform='desktop'] .valueButtonWrapper {
    justify-content: flex-start;
}

.secondaryButtonsCont {
    display: flex;
    justify-content: space-evenly;
    margin-top: var(--space-24);
}

[data-platform='desktop'] .secondaryButtonsCont {
    justify-content: flex-start;
    margin-inline-start: 2px;
}

.secondaryButton {
    max-height: 40px;
}

[data-platform='desktop'] .secondaryButton {
    max-height: 48px;
}

.itemText {
    text-align: center;
    font: var(--font-headline-h4);
    margin-bottom: var(--space-24);
}

[data-platform='desktop'] .itemText {
    text-align: start;
}

.bulletsCont {
    position: absolute;
    left: calc(100% - 16px);
    top: 0;
    right: calc(100% - 16px);
    bottom: 0;
    pointer-events: none;
    width: 12px;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--space-96);
}

[data-platform='desktop'] .bulletsCont {
    position: absolute;
    left: calc(100% - 48px);
    top: 0;
    right: calc(100% - 48px);
    bottom: 0;
    pointer-events: none;
    width: 12px;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
}
