.root {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    background-color: var(--color-mono-2);
}

.root[data-theme='dark'] {
    background-color: #141415;
}

.forcedMobileView {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 360px;
    max-height: 680px;
    margin: auto;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: var(--shadow-3);
    transform: translateZ(0);
}
