[data-platform="mobile"] .descriptionContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 100px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--space-32);
    overflow-y: auto;
}

[data-platform="desktop"] .descriptionContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: var(--space-32) 0;
    overflow-y: auto;
}

[data-platform="mobile"] .contentContainer {
    margin-top: auto;
    margin-bottom: auto;
}

[data-platform="desktop"] .contentContainer {
    margin-top: auto;
    margin-bottom: auto;
    max-width: 480px;
}
