.container {
    position: sticky;
    bottom: 0;
    width: fit-content;
    margin-inline-start: auto;
    z-index: 2;
    overflow: hidden;
    padding-top: var(--space-32);
}

.continueButton {
    position: relative;

    z-index: 2;
    min-width: 128px;
    width: fit-content;
    padding: 12px 16px;
    border-radius: 12px;
}
