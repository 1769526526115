.img {
    position: relative;
    object-fit: cover;
    box-sizing: border-box;
    user-select: none;
}

.img:active {
    opacity: 0.5;
}

.fullscreenCont {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullscreenImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: grab;
}

.closeFullScreenBtn {
    position: absolute;
    bottom: var(--space-32);
}
