.openListButton {
    width: 100%;
    padding: 24px 32px;
}

.background {
    background-color: var(--color-mono-1);
    display: flex;
    flex-direction: column;
}

.inlineCont {
    position: relative;
    width: 100%;
    height: 500px;
    box-sizing: border-box;
    background-color: var(--color-mono-0);
    display: flex;
    flex-direction: column;
    border-radius: var(--corner-radius-12);
    margin-top: var(--space-12);
}

.topBar {
    height: 64px;
    box-sizing: border-box;
    padding: var(--space-12);
    display: flex;
    align-items: center;
}

.option {
    outline: 0;

    font: var(--font-headline-h3);
    padding: 16px 24px;
    height: 64px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid var(--color-mono-2);
    color: var(--color-mono-5);
    cursor: pointer;
    user-select: none;
}

.option:hover,
.option:focus {
    background-color: var(--color-mono-2);
}

.searchBar {
    flex: 1;
    margin-inline-end: 8px;
    /* height: 48px; */
}

.inlineSearchBar {
    flex: 1;
}
