.textAreaInput {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    padding: var(--space-24) var(--space-32);
    background-color: var(--color-mono-1);
    outline: none;
    border: none;
    font: var(--font-p);
    color: var(--color-mono-5);
    resize: none;
}

.fullscreenFooter {
    height: var(--space-64);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--space-12);
}

.fullscreenHeader {
    padding: var(--space-16);
    color: var(--color-mono-4);
    font: var(--font-caption);
    border-bottom: var(--border-2);
}

.continueBtn {
    padding: 8px 12px;
}
