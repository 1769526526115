.footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
    padding: var(--space-24);

    background: linear-gradient(0deg, var(--color-mono-1) -10%, rgba(255, 255, 255, 0) 100%);
}

.continueBtn {
    position: relative;
    z-index: 2;
    width: 100%;
}

@media (prefers-color-scheme: dark) {
    .footer {
        background: linear-gradient(0deg, var(--color-mono-1) -10%, transparent 100%);
    }
}
