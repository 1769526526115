.basic {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    font: unset;

    position: relative;
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    box-sizing: border-box;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    text-align: start;

    transition-property: background-color, color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
}

.basic:focus {
    box-shadow: 0 0 0 2px var(--color-mono-4);
}

/* TextButton */
.textButton {
    border-radius: var(--corner-radius-16);
    padding: 16px 24px;
    font: var(--font-button);
    justify-content: space-between;
}

/* IconButton */
.iconButton {
    border-radius: var(--corner-radius-16);
    padding: 10px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
