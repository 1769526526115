.barWrapper {
    -webkit-tap-highlight-color: transparent;
    position: inherit;
    min-width: 100px;
    height: 40px;
    padding-inline-start: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    background-color: var( --color-mono-1 );
    border-radius: var( --corner-radius-16 );
    border: 1px solid transparent;
    cursor: pointer;
}

.barWrapper:hover {
    background-color: var( --color-mono-2 );
}

.barWrapper[data-focused="true"] {
    background-color: var( --color-mono-2 );
    border: 1px solid var( --color-mono-3 );

}

.barWrapper[data-focused="false"] {
    background-color: var( --color-mono-1 );
    border: 1px solid transparent;

}

.searchInput {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance: none;

    position: inherit;
    flex: 1;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    
    margin-inline-start: 8px;
    background-color: transparent;
    color: var( --color-mono-5 );
    font: var( --font-p );
    text-overflow: ellipsis;

}