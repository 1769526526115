.panel {
    z-index: 3;

    position: sticky;
    top: 0;

    min-height: fit-content;
    overflow: hidden;
    flex-shrink: 0;

    padding: var(--space-24);
    padding-bottom: var(--space-32);

    background-color: var(--color-mono-1);
}

.gradienPanel {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
}

.title {
    margin-bottom: var(--space-8);
    font: var(--font-headline-h3);
}

.subtitle {
    position: relative;
    font: var(--font-legend);
    color: var(--color-mono-5);
    margin: 0;
}

.progressCont {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}
