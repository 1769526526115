.mainCont {
    background-color: var(--color-mono-1);
    padding-left: var(--space-32);
    padding-right: var(--space-32);
    padding-top: var(--space-64);
    padding-bottom: var(--space-96);
    flex: 1;
    min-height: fit-content;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

[data-platform='desktop'] .mainCont {
    padding: 0;
}

.inputBottomCont {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
}

.continueBtn {
    padding: 8px 12px;
}

.textAreaPreviewCont {
    outline: 0;

    position: relative;
    width: 100%;
    min-height: 200px;
    height: fit-content;
    box-sizing: border-box;
    background-color: var(--color-mono-2);
    border-radius: var(--corner-radius-12);
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-mono-3);
    padding: 16px 20px;
    cursor: text;

    font: var(--font-p);
    color: var(--color-mono-5);
    text-overflow: ellipsis;

    transition-property: background-color border-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
}

.textAreaPreviewCont:hover {
    background-color: var(--color-mono-3);
    border-color: var(--color-mono-4);
}

.textAreaPreviewCont:focus {
    box-shadow: 0 0 0 3px var(--color-mono-4);
}

[data-disabled='true'].textAreaPreviewCont {
    pointer-events: none;
    background-color: var(--color-mono-1);
    border-color: var(--color-mono-3);
    cursor: not-allowed;
}

.textAreaDesktop {
    height: 40vh;
}
