.panel {
    flex: 1;
    position: relative;
    min-width: 320px;
    max-width: 420px;
    width: 30vw;
    box-sizing: border-box;

    overflow: hidden;
    padding: var(--space-48);
    background-color: var(--color-mono-1);
}

.gradienPanel {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    opacity: 1;
}

[data-theme='dark'] .gradienPanel {
    opacity: 0.1;
}

.container {
    position: relative;
    margin-top: 10vh; /* Should be aligned with DesktopView.module.css .pageModuleCont padding-top*/
    overflow: auto;
    max-height: calc(80vh - var(--space-48) * 2);
}

.title {
    margin-bottom: var(--space-8);
    font: var(--font-headline-h3);
}

.subtitle {
    position: relative;
    font: var(--font-legend);
    color: var(--color-mono-5);
    margin: 0;
}

.progressCont {
    position: absolute;
    width: auto;
    bottom: var(--space-48);
    right: var(--space-48);
    left: var(--space-48);
}
