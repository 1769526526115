.itemCont {
    padding: 16px 20px;
    min-height: fit-content;
    border-radius: var( --corner-radius-12 );
    align-items: flex-start;
}

.text {
    position: relative;
    font: var( --font-p );
    transition-property: color;
    transition-duration: 0.2s;
    user-select: none;
    pointer-events: none;

}

.checkmarkCont {
    position: relative;
    z-index: 1;
    width: 28px;
    min-width: 28px;
    height: 28px;
    min-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background-color: var( --color-mono-0 );
    margin-inline-end: var( --space-12 );

    transition-property: background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
}