.activationBtn {
    /* flex: ; */
    aspect-ratio: 1 / 1;
    padding: 14px;
}
.panel {
    background-color: var(--color-mono-1);
    min-width: 320px;
    min-height: 240px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    padding: var(--space-16);
}
.innerCont {
    max-width: 360px;
}
.optionItemCont {
    margin-top: var(--space-16);
    display: flex;
    align-items: center;
    gap: var(--space-12);
}

.optionLabel {
    margin-inline-start: var(--space-16);
    margin-inline-end: var(--space-8);
    color: var(--color-mono-4);
    width: 100px;
}

.formattedSizeLabel {
    margin-bottom: 0;
    margin-inline: var(--space-16);
    flex: 1;
    text-align: center;
}

.inputBox {
    display: flex;
    align-items: center;
    padding: var(--space-4);
    border: var(--border-2);
    border-radius: calc(var(--corner-radius-16) + var(--space-4));
}

.switchOption {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    height: var(--space-48);

    display: flex;
    align-items: center;
    border: var(--border-2);
    gap: var(--space-12);
    padding-inline-start: var(--space-12);
    padding-inline-end: var(--space-16);

    background-color: transparent;
    color: var(--color-mono-5);

    border-radius: var(--corner-radius-16);
}

.switchOption:focus {
    background-color: var(--color-mono-0);
    border-width: 2px;
    border-color: var(--color-mono-4);
}

.switchLabel {
    font: var(--font-button);
}

.switchLabel[data-state='off'] {
    color: var(--color-mono-4);
}
