.mainCont {
    background-color: var( --color-mono-1 );
    padding-left: var( --space-32 );
    padding-right: var( --space-32 );
    padding-top: var( --space-64 );
    padding-bottom: var( --space-96 );
    flex: 1;
    min-height: fit-content;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

[data-platform="desktop"] .mainCont {
    padding: 0;
}

.inputBottomCont {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
}

.continueBtn {
    padding: 8px 12px;  
}