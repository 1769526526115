.rootWebView {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    z-index: 5;
}

.header {
    position: relative;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.title {
    position: absolute;
    top: 0;
    right: 72px;
    left: 72px;
    bottom: 0;
    color: var(--color-mono-5);
    text-align: center;
    line-height: 3.875rem;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.panel {
    position: absolute;
    top: 64px;
    right: 8px;
    bottom: 0;
    left: 8px;
    background-color: var(--color-mono-1);
    border-radius: 8px 8px 0 0;

    overflow: hidden;
}

.iframe {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    border: none;
}

.loaderCont {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}
