.barCont{
    height: 8px;
    min-width: 100px;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.05);
}

.barCont[data-theme="dark"] {
    background-color: rgba(255,255,255,0.05);
}

.fill {
    position: relative;
    height: 100%;
    background-color: var( --color-mono-5 );
}