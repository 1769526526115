.splashContainer {
    z-index: 999;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;

    background-color: var(--color-mono-1);
}

[data-platform="desktop"] .splashContainer {
    padding: var(--space-128);
    justify-content: space-between;
}

.background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.innerCont {
    margin-bottom: var(--space-64);
}

[data-platform="mobile"] .innerCont {
    margin: var(--space-64) 0;
}

.headlineWordWrapper {
    display: inline-block;
    margin-inline-end: 1em;
    overflow: hidden;
    vertical-align: top;
}

.headline {
    margin-bottom: 0;
    line-height: 1.15em;
}

.logosCont {
    z-index: 1;
    display: flex;
    overflow: hidden;
}

[data-platform="mobile"] .logosCont {
    position: absolute;
    bottom: 40px;
    right: 40px;
    left: 40px;
}

[data-platform="mobile"] .logo {
    max-height: 72px;
}

[data-platform="desktop"] .logo {
    max-height: 96px;
}
