.container {
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    background-color: var(--color-mono-1);
    border-radius: var(--corner-radius-8);
}

.optionsCont {
    display: flex;
    flex-direction: column;
    background-color: var(--color-mono-0);
    box-shadow: var(--shadow-1);
    border-radius: var(--corner-radius-8);
    overflow: hidden;
    min-height: fit-content;
}

.btn {
    /* padding: 16px 16px; */
    justify-content: flex-end;
    border-radius: var(--corner-radius-8);
    color: var(--color-mono-2);
}
