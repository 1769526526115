.panel {
    /* animation: gradient 16s ease infinite; */
    /* background-image: radial-gradient(circle, black, white ); */
    background-image: radial-gradient(circle, var( --color-primary-a ), var( --color-primary-b ) );
    background-size: 200% 200%;
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    25% {
        background-position: 75% 25%;
    }
    50% {
        background-position: 100% 100%;
    }
    75% {
        background-position: 25% 75%;
    }
    100% {
        background-position: 0% 0%;
    }
}