.mainCont {
    background-color: var( --color-mono-1 );
    padding-left: var( --space-24 );
    padding-right: var( --space-24 );
    padding-top: var( --space-64 );
    padding-bottom: var( --space-96 );
    flex: 1;
    min-height: fit-content;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

[data-platform="desktop"] .mainCont{
    padding: 0;
}