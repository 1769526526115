.root {
    position: fixed;
    z-index: 99999;
}

.cont {
    position: relative;
    background-color: var(--color-mono-5);
    padding: var(--space-12) var(--space-24);
    margin: var(--space-12);
    color: var(--color-mono-0);
    display: flex;
    align-items: center;
}

.timerFill {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-mono-4);
    opacity: 0.5;
    z-index: 0;
}

.message {
    position: relative;
    margin-inline-end: var(--space-24);
    z-index: 1;
}

.actionBtn {
    position: relative;
    z-index: 1;
    min-width: fit-content;
}
