.itemCont {
    position: relative;
    height: 48px;
    width: 48px;
    max-width: 100%;
    min-width: fit-content;
    min-height: fit-content;
    padding: 0 var( --space-12 );
    border-radius: 24px;
    align-items: center;
    justify-content: center;
}

[data-platform="desktop"] .itemCont{
    height: 72px;
    width: 72px;
    border-radius: 36px;
}

.text {
    position: relative;
    font: var( --font-p );
    transition-property: color;
    transition-duration: 0.2s;
    user-select: none;
    pointer-events: none;
    white-space: nowrap;
}
