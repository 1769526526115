.inputCont {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    padding: var(--space-12);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inputFooter {
    /* position: absolute;
    right: 0; bottom: 0; left: 0;
    height: var( --space-64 ); */
    margin-top: var( --space-12 );
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* padding: 0 var( --space-12 ); */
}

.continueBtn {
    padding: 8px 12px;  
}