@charset "UTF-8";

@font-face {
    font-family: 'Ploni';
    font-weight: 400;
    src: url('./assets/fonts/ploni-regular-aaa.woff2') format('woff2');
}
@font-face {
    font-family: 'Ploni';
    font-weight: 500;
    src: url('./assets/fonts/ploni-medium-aaa.woff2') format('woff2');
}
@font-face {
    font-family: 'Ploni';
    font-weight: 600;
    src: url('./assets/fonts/ploni-demibold-aaa.woff2') format('woff2');
}
@font-face {
    font-family: 'Ploni';
    font-weight: 700;
    src: url('./assets/fonts/ploni-bold-aaa.woff2') format('woff2');
}

@font-face {
    font-family: 'PlexArabic';
    font-weight: 400;
    src: url('./assets/fonts/IBMPlexSansArabic-Regular.woff2') format('woff2');
}
@font-face {
    font-family: 'PlexArabic';
    font-weight: 500;
    src: url('./assets/fonts/IBMPlexSansArabic-Medium.woff2') format('woff2');
}
@font-face {
    font-family: 'PlexArabic';
    font-weight: 600;
    src: url('./assets/fonts/IBMPlexSansArabic-SemiBold.woff2') format('woff2');
}
@font-face {
    font-family: 'PlexArabic';
    font-weight: 700;
    src: url('./assets/fonts/IBMPlexSansArabic-Bold.woff2') format('woff2');
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    /* Colors */
    --color-mono-0: #ffffff;
    --color-mono-1: #f9f9f9;
    --color-mono-2: #efeff0;
    --color-mono-3: #cbcbcc;
    --color-mono-4: #7c7e83;
    --color-mono-5: #252628;
    --color-mono-max: #000000;

    --color-primary-a: #76b7f4;
    --color-primary-b: #bafeff;

    --color-link: #687287;

    /* Fonts */
    --font-headline-h1: 600 7.75rem/1 'Ploni', 'PlexArabic', sans-serif; /* Splash Headline */
    --font-headline-h2: 600 3rem/1 'Ploni', 'PlexArabic', sans-serif; /* Prolog Headline */
    --font-headline-h2-light: 400 3rem/1 'Ploni', 'PlexArabic', sans-serif; /* Number input */
    --font-headline-h3: 600 1.5rem/1.3 'Ploni', 'PlexArabic', sans-serif; /* Page Question (Title) */
    --font-headline-h4: 400 1.375rem/1.3 'Ploni', 'PlexArabic', sans-serif; /* Page Item Title */
    --font-p: 400 1.25rem/1.7 'Ploni', 'PlexArabic', sans-serif; /* Answer */
    --font-legend: 400 1.125rem/1.2 'Ploni', 'PlexArabic', sans-serif; /* Page Subtitle */
    --font-button: 600 1.25rem/1 'Ploni', 'PlexArabic', sans-serif; /* Button */
    --font-caption: 500 0.9375rem/1.33 'Ploni', 'PlexArabic', sans-serif; /* Button Caption */

    /* Shadows */
    --shadow-1: 0 4px 8px rgb(0, 0, 0, 0.2);
    --shadow-2: 0 8px 16px rgb(0, 0, 0, 0.15);
    --shadow-3: 0 16px 32px rgb(0, 0, 0, 0.1);

    /* Spaces */
    --space-4: 0.25rem;
    --space-8: 0.5rem;
    --space-12: 0.75rem;
    --space-16: 1rem;
    --space-24: 1.5rem;
    --space-32: 2rem;
    --space-48: 3rem;
    --space-64: 4rem;
    --space-96: 6rem;
    --space-128: 8rem;

    /* Corners */
    --corner-radius-8: 0.5rem;
    --corner-radius-12: 0.75rem;
    --corner-radius-16: 1rem;
    --corner-radius-24: 1.5rem;
    --corner-radius-32: 2rem;

    /* Borders */
    --border-2: 1px solid var(--color-mono-3);
    --border-3: 1px solid var(--color-mono-4);
}

[data-platform='mobile'] {
    /* fonts */
    --font-headline-h1: 600 4.25rem/1 'Ploni', 'PlexArabic', sans-serif; /* Splash Headline */
    --font-headline-h2: 600 2.5rem/1 'Ploni', 'PlexArabic', sans-serif; /* Prolog Headline */
    --font-headline-h2-light: 400 2.5rem/1 'Ploni', 'PlexArabic', sans-serif; /* Number input */
    --font-headline-h3: 600 1.375rem/1.4 'Ploni', 'PlexArabic', sans-serif; /* Page Question (Title) */
    --font-headline-h4: 400 1.25rem/1.4 'Ploni', 'PlexArabic', sans-serif; /* Page Item Title */
    --font-p: 400 1.25rem/1.3 'Ploni', 'PlexArabic', sans-serif; /* Answer */
    --font-legend: 400 1.125rem/1.2 'Ploni', 'PlexArabic', sans-serif; /* Page Subtitle */
    --font-button: 600 1.25rem/1 'Ploni', 'PlexArabic', sans-serif; /* Button */
    --font-caption: 500 0.9375rem/1.33 'Ploni', 'PlexArabic', sans-serif; /* Button Caption */
}

[data-theme='light'] {
    --color-mono-0: #ffffff;
    --color-mono-1: #f9f9f9;
    --color-mono-2: #efeff0;
    --color-mono-3: #cbcbcc;
    --color-mono-4: #7c7e83;
    --color-mono-5: #252628;
    --color-mono-max: #000000;

    --color-link: #535a6a;
}

[data-theme='dark'] {
    --color-mono-0: #000000;
    --color-mono-1: #252628;
    --color-mono-2: #55575b;
    --color-mono-3: #828288;
    --color-mono-4: #bbbbbf;
    --color-mono-5: #f9f9f9;
    --color-mono-max: #ffffff;

    --color-link: #8792a9;
}

[data-palette='000'] {
    --color-primary-a: hsl(209, 85%, 71%);
    --color-primary-b: hsl(181, 100%, 86%);
}

[data-palette='001'] {
    --color-primary-a: hsl(139, 100%, 85%);
    --color-primary-b: hsl(89, 100%, 92%);
}

[data-palette='002'] {
    --color-primary-a: hsl(334, 87%, 85%);
    --color-primary-b: hsl(24, 100%, 90%);
}

[data-palette='003'] {
    --color-primary-a: hsl(51, 95%, 63%);
    --color-primary-b: hsl(63, 100%, 77%);
}

[data-palette='004'] {
    --color-primary-a: hsl(37, 95%, 63%);
    --color-primary-b: hsl(41, 100%, 81%);
}

html,
body {
    overflow: hidden;
    height: 100%;
    width: 100%;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: none;

    margin: 0;
    padding: 0;

    font: var(--font-p);
    color: var(--color-mono-5);

    /* Ploni spacing fixes (suggested by Meir Sadan):*/
    letter-spacing: 0.02rem;
    word-spacing: -0.05rem;

    background-color: var(--color-mono-1);
}

h1 {
    position: relative;
    font: var(--font-headline-h1);
    color: var(--color-mono-5);
    margin-top: 0;
    min-height: 0.5em;
}

h2 {
    position: relative;
    font: var(--font-headline-h2);
    color: var(--color-mono-5);
    margin-top: 0;
    margin-bottom: var(--space-24);
    min-height: 0.5em;
}

h3 {
    position: relative;
    font: var(--font-headline-h3);
    color: var(--color-mono-5);
    margin-top: 0;
    margin-bottom: var(--space-12);
    min-height: 0.5em;
}

h4 {
    position: relative;
    font: var(--font-headline-h4);
    color: var(--color-mono-5);
    margin-top: 0;
    margin-bottom: var(--space-24);
    min-height: 0.5em;
}

p {
    position: relative;
    font: var(--font-p);
    color: var(--color-mono-5);
    margin: 0;
    min-height: 0.5em;
}

b {
    font-weight: 700;
}

a,
.link {
    text-decoration: none;
    color: var(--color-link);
    border-bottom: 1px solid var(--color-link);
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}

a:hover,
.link:hover {
    background-color: var(--color-mono-2);
}

a:active {
    background-color: var(--color-mono-5);
    color: var(--color-mono-0);
    border-bottom: 1px solid var(--color-mono-0);
}

fieldset {
    margin: unset;
    margin-inline: unset;
    margin-block: unset;
    padding: unset;
    padding-inline: unset;
    padding-block: unset;
    min-inline-size: unset;
    border: none;
}

legend {
    padding-inline: unset;
}

@media (max-width: 320px) {
    [data-platform='mobile'] {
        --font-headline-h1: 600 3rem/1 'Ploni', 'PlexArabic', sans-serif; /* Splash Headline */
        --font-headline-h2: 600 2rem/1 'Ploni', 'PlexArabic', sans-serif; /* Prolog Headline */
        --font-headline-h2-light: 400 2rem/1 'Ploni', 'PlexArabic', sans-serif; /* Number input */
        --font-headline-h3: 600 1.125rem/1.4 'Ploni', 'PlexArabic', sans-serif; /* Page Question (Title) */
        --font-headline-h4: 400 1.125rem/1.4 'Ploni', 'PlexArabic', sans-serif; /* Page Item Title */
        --font-p: 400 1.125rem/1.3 'Ploni', 'PlexArabic', sans-serif; /* Answer */
        --font-legend: 400 0.875rem/1.2 'Ploni', 'PlexArabic', sans-serif; /* Page Subtitle */
        --font-button: 600 1.125rem/1 'Ploni', 'PlexArabic', sans-serif; /* Button */
        --font-caption: 500 0.75rem/1.33 'Ploni', 'PlexArabic', sans-serif; /* Button Caption */
    }
}
