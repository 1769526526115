.textArea {

    /* appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none; */
    resize: none;

    position: relative;
    width: 100%;
    min-height: 200px;
    box-sizing: border-box;
    background-color: var( --color-mono-2 );
    border-radius: var( --corner-radius-12 );
    border-width: 1px;
    border-style: solid;
    border-color: var( --color-mono-3 );
    padding: var(--space-32);
    cursor: text;
    overflow-y: auto;
    
    font: var( --font-p );
    color: var( --color-mono-5 );    
    text-overflow: ellipsis;

    transition-property: background-color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
}

.textArea:focus {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    outline: none;

    background-color: var(--color-mono-0);
    border-color: var(--color-mono-4);
}

.textArea:disabled {
    background-color: var(--color-mono-1);
    border-color: var(--color-mono-3);
    cursor: not-allowed;
}