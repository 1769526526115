.mainCont {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    z-index: 3;
}

.loaderCont {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.iframe {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    border: none;
    /* background-color: white; */
}

.desktopContinueBtnCont {
    position: absolute;
    right:0; bottom: 0; left:0;
    padding: var( --space-24 );
}