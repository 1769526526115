.rootCont {
    width: 100%;
    box-sizing: border-box;
}

.labelsCont {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 40px;
    margin-bottom: var(--space-12);
}

.rangeLabels {
    font: var(--font-caption);
    font-size: 1.125rem;
    color: var(--color-mono-4);
}

.valueLabel {
    margin-bottom: 0;
    font-size: 1.5rem;
}

/* Basic Slider */

.barBackground {
    outline: 0;

    position: relative;
    height: 48px;
    border-radius: 24px;
    width: 100%;
    background-color: var(--color-mono-2);
    overflow: hidden;
    cursor: grab;

    /* transition: background-color 0.2s ease-out; */
}

.barBackground:focus {
    box-shadow: 0 0 0 3px var(--color-mono-4);
}

/* .barBackground:hover {
    background-color: var( --color-mono-3 );
} */

.knob {
    position: absolute;
    background-color: var(--color-mono-5);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    pointer-events: none;
}

.fill {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}
