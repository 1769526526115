.container {
    position: relative;
    width: 100%;
}

.input {
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance: none;

    position: inherit;
    width: 100%;
    height: 110px;
    box-sizing: border-box;
    outline: none;
    background-color: var( --color-mono-2 );
    border-radius: var( --corner-radius-12 );
    border-width: 1px;
    border-style: solid;
    border-color: var( --color-mono-3 );
    padding: 24px 32px;
    
    color: var( --color-mono-5 );
    font: var( --font-headline-h2-light );
    text-align: center;
    text-overflow: ellipsis;

    transition-property: border-color, background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}

.input:focus {
    border-color: var( --color-mono-4 );
    background-color: var(--color-mono-0);
}

.input:disabled {
    background-color: var(--color-mono-1);
    border-color: var(--color-mono-3);
    cursor: not-allowed;
}

.widthTester {
    position: absolute;
    color: transparent;
    font: var( --font-headline-h2-light );
    pointer-events: none;
    user-select: none;

}