.endingContainer {
    z-index: 4;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--space-32);
    overflow-y: auto;
}

[data-platform="mobile"] .endingContainer {
    /* bottom: var(--space-96); */
    padding-bottom: var(--space-96);
}

[data-platform="desktop"] .endingContainer {
    padding: var(--space-64);
}

.gradientBg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.innerCont {
    margin: auto;
}

[data-platform="mobile"] .innerCont {
    width: 100%;
}

[data-platform="desktop"] .innerCont {
    max-width: 600px;
}

.title {
    margin-bottom: var(--space-24);
}

[data-platform="mobile"] .callToActionCont {
    position: fixed;
    right: var(--space-32);
    bottom: var(--space-32);
    left: var(--space-32);
}

[data-platform="desktop"] .callToActionCont {
    position: sticky;
    bottom: 0;
    margin-top: var(--space-64);
}

[data-platform="mobile"] .callToActionBtn {
    width: 100%;
}
