/* MOBILE */
[data-platform='mobile'] .openingContainer {
    position: absolute;
    top: 62px;
    right: 0;
    bottom: 100px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--space-32);
    overflow-y: auto;
}

.topCont {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: var(--space-12);
    gap: var(--space-8);
    z-index: 2;
    /* height: var(--space-48); */
}

.titleCont {
    margin-top: auto;
}

.subtitleCont {
    margin-bottom: auto;
}

.bottomLinksCont {
    margin-top: var(--space-32);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.bottomLinkLabel {
    font: var(--font-caption);
    color: var(--color-mono-4);
    line-height: normal;
    cursor: pointer;
}

.bottomLinkLabel:hover {
    background-color: var(--color-mono-2);
    color: var(--color-mono-5);
}

.linkSepatator {
    font: var(--font-caption);
    color: var(--color-mono-4);
    line-height: normal;
    user-select: none;
}

/* DESKTOP */

[data-platform='desktop'] .openingContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: var(--space-32) 0;
    overflow-y: auto;
}

[data-platform='desktop'] .contentContainer {
    max-width: 480px;
    margin: auto;
}
